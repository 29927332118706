.rdtCounters,
.rdt,
.rdtOpen,
.rdtPicker,
.rdtTime,
.rdtPrev,
.rdtSwitch,
.rdtNext {
  background-color: var(--table-background);
  color: var(--body_color);
}
.dateTimeDiv {
  display: flex;
}
.dateTimeSpan {
  margin: 0 2vh;
}
@media (max-width: 768px) {
  .dateTimeDiv {
    display: block;
  }
  .dateTimeSpan {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
