.subagent-container {
  width: 100%;
  background-color: var(--table-background);
  border-radius: 5px;
}
.subagent-header {
  width: 96%;
  margin: auto;
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.4);
}

.subagent-body {
  width: 96%;
  margin: auto;
}
.subagent-cell {
  text-align: start;
  padding: 5px;
  align-content: center;
}
.subagent-row {
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.2);
}
.noRecorddiv {
  color: grey;
}
.subAgentPermission {
  width: 85%;
  margin: 1vh auto;
  align-items: center;
  align-content: center;
  display: flex;
}
.subAgentPermission span {
  align-items: center;
}
.buttonCss {
  margin: 20px auto;
  align-items: center;
}
