.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.dashboard-card {
  border: 1px solid grey;
  margin: 1vh;
  flex: 1 1 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  min-width: 150px;
  background-color: var(--body_textBox);
}

@media (max-width: 768px) {
  .dashboard-card {
    flex: 1 1 45%;
  }
  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .dashboard-card {
    flex: 1 1 100%;
  }
  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
