.platform-container {
  width: 100%;
  position: relative;
  background-color: var(--table-background);
  border-radius: 5px;
}
.platform-header {
  padding: 5px;
  width: 96%;
  margin: auto;
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.4);
}

.platform-body {
  width: 96%;
  margin: auto;
}
.platform-cell {
  text-align: start;
  padding: 5px;
  align-content: center;
  height: 48px;
}

.platform-row {
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.2);
}

.no-record-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oRecordDiv {
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}
