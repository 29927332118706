/* MasterLayout.css */
.main-container {
  display: flex;
}

.sidebar {
  flex: 1; /* Set the width of the sidebar as needed */
  max-width: 300px; /* Adjust the maximum width as needed */
}

.outlet {
  flex: 3; /* The Outlet takes up the remaining space */
}
