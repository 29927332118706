.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: "#012970";
  /* font-family: "Nunito", sans-serif; */
}
