#main {
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}
.top-item {
  display: flex;
  justify-content: space-between;
}
