/* Import Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* Import Nunito font */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

:root {
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --bgColor: #f6f9ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: "Open Sans", sans-serif; */
  color: #444444;
  background-color: var(--body_background);
  color: var(--body_color);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
} */

.text-color {
  color: var(--body_color);
}
.inputStyle {
  width: 90%;
  height: 35px;
  border-radius: 5px;
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box;
  color: var(--body_color);
  background: var(--body_textBox);
}
.inputStyle:focus {
  background: var(--body_textBox);
  color: var(--body_color);
}
.inputStyle::placeholder {
  color: var(--placeholder-color);
}
.inputStyle1 {
  background: var(--body_textBox);
  color: var(--body_color);
}
.custom-swal {
  background-color: var(--body_background) !important;
  color: var(--body_color);
  background: var(--body_background) !important;
}

.card-css {
  background-color: var(--table-background);
  color: var(--body_color);
}
.mobile-view-div {
  display: flex;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
  max-width: 200px;
}
.input-css {
  width: 100%;
  height: 100%;
  background-color: var(--body_background);
  border: none;
  color: var(--body_color);
}
.dateSearch-div {
  display: flex;
  justify-content: space-between;
}
.td-line {
  border-right: 1px solid rgba(175, 164, 164, 0.4);
  padding-left: 6px;
}

.respond-Select {
  background-color: var(--table-background);
  color: var(--body_color);
}

.refund-Input {
  background-color: var(--table-background);
  color: var(--body_color);
  height: 34px;
  /* width: ; */
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid rgba(175, 164, 164, 0.8);
}
.proceed-Input {
  background-color: var(--table-background);
  color: var(--body_color);
  height: 34px;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid rgba(175, 164, 164, 0.8);
  width: 100%;
}
.downloadList-background {
  background-color: var(--gameList_background);
  border: 1px solid grey;
  margin: 1vh 1vh;
}

@media (max-width: 768px) {
  .mobile-overflow {
    overflow: auto;
  }
  .truncate {
    -webkit-line-clamp: 1; /* Adjust the number of lines for mobile view */
  }
  .mobile-fontSize {
    font-size: 13px;
  }
  .mobile-view-div {
    display: block;
  }
  .dateSearch-div {
    display: inline-block;
  }
  .dateRange-div {
    margin-bottom: 5px;
  }
}
